import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Soldier to Civilian: networking as a veteran",
  "author": "Anthony",
  "date": "2019-04-29T17:26:52.000Z",
  "categories": ["Military Transition"],
  "slug": "soldier-to-civilian-networking-as-a-veteran",
  "draft": false,
  "meta_title": "Soldier to Civilian: networking as a veteran"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`When I left the military in 2007, my next move was business school. As a fresh veteran, I knew little about the private sector, let alone business. What I learned one short week after arriving was that networking is everything.`}</p>
    <p>{`Networking can be unusual and unnatural for soldiers. We do little of it in our military careers. For the most part, our OER and NCOERs (military performance appraisals) speak for our professionalism and experience. The Army and sister branches rely on this system, in my opinion, for these three reasons:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Efficiency`}</strong>{` – The Army is the large organization with an enormous Human Resources challenge.`}</li>
      <li parentName="ul"><strong parentName="li">{`Continuity`}</strong>{` – If everyone operates under the same grading system, with all soldiers given equal opportunity to succeed (shine), promotions and job assignments should be fair.`}</li>
      <li parentName="ul"><strong parentName="li">{`Community`}</strong>{` – When we enter the service we’re designated to a career within a specific branch or corps. In short, everyone will eventually know each other or be one degree away from knowing each other before long.`}</li>
    </ul>
    <p>{`This system is designed to practically eliminate the need to network the way our counterparts do in the private sector. So where does that leave veterans as they transition into the civilian workforce?`}</p>
    <p>{`My first week of business school was a networking nightmare. Everyone was doing their best to leave strong first impressions while discovering what each other’s past careers were and where future careers were headed. This felt hokey to me and I was reluctant to participate. After a few weeks I made some friends who asked, "why aren’t you attending networking events?" I explained my reasons and they explained why I was making a mistake.`}</p>
    <p><strong parentName="p">{`Here’s why networking is everything`}</strong></p>
    <p>{`I graduated from Cornell in 2009. Since then I have had three jobs. I worked at a New York start-up straight out of school doing business development. After this experience, I moved to San Francisco and landed a job doing operations management with SRI. Finally, in early 2011, I left to begin my first of three businesses for military service members. My third and the one I'm most proud of is `}<a parentName="p" {...{
        "href": "http://www.purepost.co/"
      }}>{`Purepost.`}</a></p>
    <p>{`Over the past 9 years, what got me work and allowed me to start funding my company was meeting with hundreds of people over coffee, lunch, dinner, drinks, running groups, biking groups, dinner parties… you get the idea. It’s all about networking.`}</p>
    <p>{`In the private sector, every professional is looking for the next opportunity, even if they say they aren’t. I was in the Army for eight years and never networked to get a job. My father was a Cold War soldier for 21 years and never networked to get a job. It’s because you’re always, technically, in the same organization.`}</p>
    <p>{`In the private sector people don’t share their performance appraisals when interviewing for a new job with a new company. What they share is a `}<a parentName="p" {...{
        "href": "http://www.purepost.co/"
      }}>{`resume`}</a>{` and, nowadays, their `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/anthonyrgarciajr"
      }}>{`LinkedIn`}</a>{` profile.`}</p>
    <p>{`I’m not going to tell you how to get over the hokey feeling of networking. You are a veteran or a Soldier, Airman, Marine, or Sailor. You’ve been in tougher situations. What I `}<em parentName="p">{`can`}</em>{` provide are some tips to help with the networking process to ease your transition as a veteran entering the private sector:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Find a mentor and coach who has made a successful transition`}</strong>{` from military service to the private sector. No need to re-create the networking wheel — learn from another veterans' mistakes and successes.`}</li>
      <li parentName="ul"><strong parentName="li">{`Find a mentor and coach who has been in the private sector`}</strong>{` for their entire career. They can provide just as much guidance and assistance as a veteran. In many cases, their advice may be more valuable as you begin.`}</li>
      <li parentName="ul"><strong parentName="li">{`Prior to leaving service, start translating your military experience`}</strong>{` to private sector relevance. You’ve accomplished some amazing feats – now you need to translate them. Before you know it, you’re a year way from leaving the military and need that `}<a parentName="li" {...{
          "href": "http://www.purepost.co/"
        }}>{`resume`}</a>{` and a year of preparation to find your next job.`}</li>
      <li parentName="ul"><strong parentName="li">{`Create a LinkedIn account`}</strong>{` a year to six months prior to leaving the military. LinkedIn is currently providing a free, one-year subscription for their premium account. The premium account allows you to send messages between other LinkedIn users and gives access to benefits to other features. This is how you get a free premium account: a. Join LinkedIn and complete the profile. b. Be sure to add your military experience, so LinkedIn can verify you are serving or have served. c. Join the `}<a parentName="li" {...{
          "href": "http://www.linkedin.com/groups?home=&gid=4466143&trk=anet_ug_hm&goback=.npp_anthony*5garcia%2F7%2F462%2Fa25"
        }}>{`Veteran Mentor Network`}</a>{` and then join the subgroup, “LI Job Seeker Subscription.”  LinkedIn will later connect you to information on the premium upgrade.`}</li>
      <li parentName="ul"><strong parentName="li">{`Create a 30-second personal pitch`}</strong>{`. What’s your story?`}</li>
      <li parentName="ul"><strong parentName="li">{`Create a`}</strong>{` `}<a parentName="li" {...{
          "href": "http://www.meetup.com/find/"
        }}><strong parentName="a">{`Meetup`}</strong></a>{` `}<strong parentName="li">{`profile`}</strong>{` and start searching for groups that interest you in your local education or business community. Meetup will help you meet non-military people who enjoy the same activities and have the same interests as you do. This is a good way to network without feeling like you are ‘actively’ networking.`}</li>
      <li parentName="ul"><strong parentName="li"><em parentName="strong">{`Be courageous`}</em></strong>{` and put yourself out there! It’s scary at first and seems uncomfortable, but as with everything else, you will succeed and land yourself the jobs and opportunities you deserve and desire!`}</li>
    </ul>
    <p>{`Anthony Garcia`}</p>
    <p>{`Purepost, CEO and Co-Founder`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      